import { GITHUB_ASSETS_HOST_URL } from "../../../../helpers/api";
export const SidebarData = [
  {
    title: "Dashboard",
    path: "/educator/dashboard",
    icon: (
      <img
        src={
          GITHUB_ASSETS_HOST_URL + `img/Educator_Icon/2 (1).png`

        }
        alt="icon"
        style={{ width: "25px" }}
      />
    ),
    cName: "nav-text-educator",
  },

  // {
  //   title: "Live Class",
  //   path: "/educator/liveclass",
  //   icon: (
  //     <img
  //       src={
  //         GITHUB_ASSETS_HOST_URL + `img/Educator_Icon/1 (12).png`
  //         // require("../../../../assets/img/Educator_Icon/1 (12).png").default
  //       }
  //       alt="icon"
  //       style={{ width: "25px" }}
  //     />
  //   ),
  //   cName: "nav-text-educator yellow",
  // },

  // {
  //   title: "Meekonis",
  //   path: "/educator/meekonis",
  //   icon: (
  //     <img
  //       src={
  //         GITHUB_ASSETS_HOST_URL + `img/Educator_Icon/1 (13).png`
  //         // require("../../../../assets/img/Educator_Icon/1 (13).png").default
  //       }
  //       alt="icon"
  //       style={{ width: "25px" }}
  //     />
  //   ),
  //   cName: "nav-text-educator yellow",
  // },

  // {
  //   title: "My_Questions",
  //   path: "/educator/myquestions",
  //   icon: (
  //     <img
  //       src={
  //         GITHUB_ASSETS_HOST_URL + `img/Educator_Icon/1 (14).png`
  //         // require("../../../../assets/img/Educator_Icon/1 (14).png").default
  //       }
  //       alt="icon"
  //       style={{ width: "20px", height: "25px" }}
  //     />
  //   ),
  //   cName: "nav-text-educator yellow",
  // },

  {
    title: "My Classes",
    path: "/educator/classes",
    icon: (
      <img
        src={
          GITHUB_ASSETS_HOST_URL + `img/Educator_Icon/1 (6).png`

        }
        alt="icon"
        style={{ width: "25px" }}
      />
    ),
    cName: "nav-text-educator",
  },

  {
    title: "Calendar",
    path: "/educator/calendar",
    icon: (
      <img
        src={
          GITHUB_ASSETS_HOST_URL + `img/Educator_Icon/1 (10).png`

        }
        alt="icon"
        style={{ width: "25px" }}
      />
    ),
    cName: "nav-text-educator",
  },
  {
    title: "My Space",
    path: "/educator/my-space",
    icon: (
      <img
        src={
          GITHUB_ASSETS_HOST_URL + `img/Educator_Icon/1 (4).png`

        }
        alt="icon"
        style={{ width: "25px" }}
      />
    ),
    cName: "nav-text-educator",
  },
  {
    title: "Create Test",
    path: "/educator/test",
    icon: (
      <img
        src={
          GITHUB_ASSETS_HOST_URL + `img/Educator_Icon/1 (9).png`

        }
        alt="meeko-icon-4"
        style={{ width: "25px" }}
      />
    ),
    cName: "nav-text-educator",
  },
  {
    title: "Finance",
    path: "/educator/finance",
    icon: (
      <img
        src={
          GITHUB_ASSETS_HOST_URL + `img/Educator_Icon/1 (8).png`

        }
        alt="meeko-icon-5"
        style={{ width: "25px" }}
      />
    ),
    cName: "nav-text-educator",
  },
  {
    title: "Reviews",
    path: "/educator/review-rating",
    icon: (
      <img
        src={
          GITHUB_ASSETS_HOST_URL + `img/Educator_Icon/1 (3).png`

        }
        alt="help"
        style={{ width: "25px" }}
      />
    ),
    cName: "nav-text-educator",
  },
  {
    title: "Video Requests",
    path: "/educator/video-requests",
    icon: (
      <img
        src={
          GITHUB_ASSETS_HOST_URL + `img/Educator_Icon/1 (5).png`

        }
        alt="help"
        style={{ width: "25px" }}
      />
    ),
    cName: "nav-text-educator",
  },
  {
    title: "Class History",
    path: "/educator/class-history",
    icon: (
      <img
        src={
          GITHUB_ASSETS_HOST_URL + `img/Educator_Icon/1 (2).png`

        }
        alt="help"
        style={{ width: "25px" }}
      />
    ),
    cName: "nav-text-educator",
  },
  {
    title: "Meeko Journey",
    path: "/educator/meeko-journey",
    icon: (
      <img
        src={
          GITHUB_ASSETS_HOST_URL + `img/Educator_Icon/1 (7).png`

        }
        alt="help"
        style={{ width: "25px" }}
      />
    ),
    cName: "nav-text-educator",
  },
  {
    title: "Reports",
    path: "/educator/reports",
    icon: (
      <img
        src={
          GITHUB_ASSETS_HOST_URL + `img/Educator_Icon/report.png`

        }
        alt="help"
        style={{ width: "25px" }}
      />
    ),
    cName: "nav-text-educator",
  },
  {
    title: "Growth",
    path: "/educator/growth",
    icon: (
      <img
        src={
          GITHUB_ASSETS_HOST_URL + `img/Educator_Icon/1 (11).png`

        }
        alt="help"
        style={{ width: "25px" }}
      />
    ),
    cName: "nav-text-educator",
  },
];
