import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { SidebarData } from "./SideBarData";
import { useHistory } from "react-router";
import { AnyAction } from "redux";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { logoutUser } from "../../../../store/auth/action";
import { ApplicationState } from "../../../../store";
import api, {
  ASSETS_HOST_URL,
  decryptAPIRes,
  encryptAPIRes,
  FRONT_MEETUBE,
  FRONT_MEETUBE_ENDPOINT,
  GITHUB_ASSETS_HOST_URL,
} from "../../../../helpers/api";
import "./EduHeader.scss";
import Countdown from "react-countdown";
import moment from "moment";
import Popup from "../../../layout/Comman_Popup/Popup";
import FreeclassPopup from "../../../layout/FreeClass_popup/FreeclassPopup";
import {
  getNotification,
  setReadNotification,
  allReadNotification,
} from "../../../../helpers/socket";
import { NavLink } from "react-router-dom";
import axios, { AxiosRequestConfig } from "axios";
interface PropsFromState {
  profile: any;
  userData: any;
  loading: any;
  educatorNotificationCounter: any;
  educatorNotification: any;
}

interface PropsFromDispatch {
  logoutUser: any;
}

type AllProps = PropsFromState & PropsFromDispatch;

const EduHeader: React.FC<AllProps> = ({
  profile,
  logoutUser,
  userData,
  loading,
  educatorNotificationCounter,
  educatorNotification,
}) => {
  const [eduProfile, setEduProfile]: any = useState("");
  const [sidebar, setSidebar] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [classPopup, setClassPopup] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const [nextClassDetail, setNextClassDetail]: any = useState([]);
  const history = useHistory();
  const showSidebar = () => setSidebar(!sidebar);
  const [DropDownIsOpen, setDropDownIsOpen] = useState(false);
  const [notificationPannel, setNotificationPannel]: any = useState(false);
  const [meetubeBtnText, setMeetubeBtnText]: any = useState("");
  const [meetubeUserId, setMeetubeUserId]: any = useState("");
  useEffect(() => {
    getNotification(-1);
    window.addEventListener("resize", () => setWidth(window.innerWidth));
  }, []);

  useEffect(() => {}, [educatorNotificationCounter, educatorNotification]);

  useEffect(() => {
    if (userData) {
      if (userData && userData.profilePic) {
        localStorage.setItem("profile", userData.profilePic.path);
      }
    }
    callClassCourseApi();
    let profilePic = localStorage.getItem("profile");
    if (profilePic) {
      setEduProfile(profilePic);
    }
    // let uDetail = localStorage.getItem('userDetail')
    // if(uDetail) {
    //   const userDetail: any = JSON.parse(uDetail)
    //   checkMeetubeUser(userDetail.email)
    // }
  }, []);

  useEffect(() => {
    if (localStorage.getItem("profile")) {
      setEduProfile(localStorage.getItem("profile"));
    }
  }, [JSON.parse(JSON.stringify(localStorage.getItem("profile")))]);

  useEffect(() => {
    if (localStorage.getItem("profile")) {
      setEduProfile(localStorage.getItem("profile"));
    }
  }, [profile]);

  const checkMeetubeUser = async (email) => {
    const token = localStorage.getItem("token");
    const config: AxiosRequestConfig = {
      method: "get",
      url: FRONT_MEETUBE_ENDPOINT + `user/checkuser/${btoa(email)}`,
      headers: {
        "x-authorization": `${token}`,
      },
    };
    axios(config)
      .then(function (response) {
        var payload = decryptAPIRes(response.data.data);
        console.log(payload, "======payload");
        if (payload.code == 200) {
          //not user on meetube
          setMeetubeBtnText("Sign Up With Meetube");
        } else {
          //user on meetube
          setMeetubeBtnText("Sign In With Meetube");
          setMeetubeUserId(payload.data);
        }
      })
      .catch(function (error) {
        var data = decryptAPIRes(error.response.data);
        console.log(data, "=======data");
      });
  };

  const callClassCourseApi = async () => {
    try {
      console.log("callClassCourseApi -------------callClassCourseApi");
      const payload = await api.get("educator/liveclassinfo/");
      if (payload.status === 200) {
        if (payload?.data) {
          console.log(
            payload?.data?.nextClass,
            "=======payload?.data?.nextClass"
          );
          setNextClassDetail(payload?.data?.nextClass);
        }
      }
    } catch (e) {
      console.log("e", e);
    }
  };

  const ShowPopUpHandler = (index) => {
    setShowPopup(index);
  };

  const PopUpHandler = () => {
    setClassPopup((classPopup) => !classPopup);
  };

  const getAllNotification = async () => {
    if (!notificationPannel) {
      getNotification(-1);
    }
  };

  const CountdownRenderer = ({ total, days, hours, minutes, seconds }: any) => {
    if (total === 300000) {
      callClassCourseApi();
    }
    return (
      <>
        {total && total <= 300000 ? (
          <>
            <div className="Navbar_Button">
              <span className="Navbar_Button_Text timer">
                <div className="timer-box-design">{hours}</div>
                <div className="timer-box-design">{minutes}</div>
                <div className="timer-box-design">{seconds}</div>
              </span>
            </div>
            <div
              className={`Navbar_Button ${
                total >= 300000 ? "disabled blick_disable" : ""
              }`}
              data-tut="reactour__Go_Live"
            >
              <div className="Navbar_Button_Icon">
                <img
                  src={GITHUB_ASSETS_HOST_URL + `img/Edu_Dashboard/Go_Live.svg`}
                  alt="Logo"
                  className="Icon"
                />
              </div>
              <span
                className="Navbar_Button_Text"
                onClick={() => openLiveClass(nextClassDetail)}
              >
                <span> Go Live</span>
              </span>
            </div>
          </>
        ) : (
          <>
            {nextClassDetail && nextClassDetail.classId ? (
              <div
                className={`Navbar_Button ${
                  total >= 300000 ? "disabled blick_disable" : ""
                }`}
                data-tut="reactour__Go_Live"
              >
                <div className="Navbar_Button_Icon">
                  <img
                    src={
                      GITHUB_ASSETS_HOST_URL + `img/Edu_Dashboard/Go_Live.svg`
                    }
                    alt="Logo"
                    className="Icon"
                  />
                </div>
                <span
                  className="Navbar_Button_Text"
                  onClick={() => openLiveClass(nextClassDetail)}
                >
                  <span> Go Live</span>
                </span>
              </div>
            ) : null}
            {/* <div className={`Navbar_Button ${total >= 300000 ? 'disabled' : ''}`}>
             <div className="Navbar_Button_Icon">
               <img
                 src={
                  //  require("../../../../assets/img/Edu_Dashboard/Go_Live.svg").default
                 }
                 alt="Logo"
                 className="Icon"
               />
             </div>
             <span className="Navbar_Button_Text" onClick={() => openLiveClass(nextClassDetail)}>
               Go Live
             </span>
           </div> */}
          </>
        )}
      </>
    );
  };

  const openLiveClass = (data) => {
    if (data) {
      history.push(`/educator/live-class/${data?.classId}/${data?.sessionId}`);
    }
  };

  const logoutHandler = () => {
    return setShowPopup(!showPopup);
  };

  const signInWithMeetube = async () => {
    try {
      const payload: any = await api.get("/educator/meetube/");
      if (payload.status == 200) {
        if (meetubeUserId != "") {
          console.log(payload.data, "====payload 1");
          window.open(
            FRONT_MEETUBE + `meeko/signin/${btoa(meetubeUserId)}`,
            "_blank"
          );
        } else {
          const token = localStorage.getItem("token");
          const encryptData = encryptAPIRes({
            firstName: payload.data.firstName,
            lastName: payload.data.lastName,
            email: payload.data.email,
            mobile: payload.data.mobile,
            password: payload.data.password,
          });
          const config: AxiosRequestConfig = {
            method: "post",
            url: FRONT_MEETUBE_ENDPOINT + `user/meekousersignup/`,
            headers: {
              "x-authorization": `${token}`,
            },
            data: encryptData?.data,
          };
          axios(config)
            .then(function (response) {
              var payload = decryptAPIRes(response.data.data);
              if (payload.code == 200) {
                console.log(payload.data.details._id, "====payload 2");
                window.open(
                  FRONT_MEETUBE +
                    `meeko/signin/${btoa(payload.data.details._id)}`,
                  "_blank"
                );
              } else {
                console.log(payload.data, "======payload.data");
              }
            })
            .catch(function (error) {
              var data = decryptAPIRes(error.response.data);
              console.log(data, "=======data");
            });
          // window.open(FRONT_MEETUBE + 'meeko/signup', '_blank');
        }
      } else {
        console.log(payload, "====payload 3");
      }
    } catch (err: any) {
      console.log(err, "==err");
    }
  };

  return (
    <>
      {width > 1140 ? (
        <div className="Edu_Dashboard_Header">
          <div className="Logo_Container">
            <Link to="/">
              <img
                src={GITHUB_ASSETS_HOST_URL + `img/meeko_logo.png`}
                alt="Logo"
                className="Logo"
              />
            </Link>
          </div>
          <div className="Navbar_Right_Side_Container">
            {/* <div className="signin_with_meetube_btn">
              <button
                onClick={() => {
                  signInWithMeetube()
                }}
              >
                {meetubeBtnText}
              </button>
            </div> */}
            <div className="free_class_btn">
              <button
                onClick={() => {
                  PopUpHandler();
                }}
              >
                Get More Exposure
              </button>
            </div>
            <FreeclassPopup
              PopupShown={classPopup}
              ActionToParent={PopUpHandler}
              closePopup={() => setClassPopup(false)}
            />
            <Countdown
              date={new Date(nextClassDetail?.date)}
              renderer={(data) => CountdownRenderer(data)}
            />
            {nextClassDetail?.date ? null : (
              <div
                className={`Navbar_Button disabled blick_disable`}
                data-tut="reactour__Go_Live"
              >
                <div className="Navbar_Button_Icon">
                  <img
                    src={
                      GITHUB_ASSETS_HOST_URL + `img/Edu_Dashboard/Go_Live.svg`
                    }
                    alt="Logo"
                    className="Icon"
                  />
                </div>
                <span
                  className="Navbar_Button_Text"
                  onClick={() => openLiveClass(nextClassDetail)}
                >
                  <span> Go Live</span>
                </span>
              </div>
            )}

            <div
              className="Navbar_Single_Icon"
              onClick={() => {
                setNotificationPannel(!notificationPannel);
                getAllNotification();
              }}
            >
              {educatorNotificationCounter &&
              educatorNotificationCounter > 0 ? (
                <div className="notification-count">
                  {educatorNotificationCounter &&
                  educatorNotificationCounter > 99
                    ? `99+`
                    : educatorNotificationCounter}
                </div>
              ) : null}
              <img
                src={
                  GITHUB_ASSETS_HOST_URL + `img/Edu_Dashboard/notification.png`
                }
                alt="Notification"
                className="Icon"
              />
            </div>

            <div
              className="Navbar_Single_Icon"
              onClick={() => history.push(`/educator/chat`)}
            >
              <img
                src={GITHUB_ASSETS_HOST_URL + `img/Edu_Dashboard/chat.png`}
                alt="chat"
                className="Icon"
              />
            </div>

            <div className="Navbar_Single_Icon">
              <img
                src={GITHUB_ASSETS_HOST_URL + `img/Edu_Dashboard/Meeekoin.png`}
                alt="Meekoin"
                className="Icon"
                data-tut="reactour__Meekoins"
              />
            </div>

            <div
              className="Navbar_Single_Icon_DropDown"
              onClick={() => {
                setDropDownIsOpen((value) => !value);
              }}
              data-tut="reactour__Profile"
            >
              <img
                src={eduProfile ? ASSETS_HOST_URL + eduProfile : ""}
                alt="Profile"
                className="Profile"
              />

              <div className="DropDownIconContainer">
                <img
                  src={
                    GITHUB_ASSETS_HOST_URL + `img/Edu_Dashboard/Gray_Down.png`
                  }
                  alt="DropDown"
                  className="DropDownIcon"
                />
              </div>
              {DropDownIsOpen && (
                <>
                  <div className="dropdown_Main_Container">
                    <div
                      onClick={() => {
                        history.push("/educator/education-profile");
                      }}
                    >
                      <img
                        src={GITHUB_ASSETS_HOST_URL + `img/user-icon.png`}
                        alt="Profile"
                      />
                      Profile
                    </div>

                    <div
                      className="refer_class_block"
                      onClick={() => {
                        history.push("/educator/refer-and-earn");
                      }}
                    >
                      <img
                        src={
                          GITHUB_ASSETS_HOST_URL + `img/Educator_Icon/1 (1).png`
                        }
                        alt="Refer"
                      />
                      Refer & Earn
                    </div>

                    <div
                      className="refer_class_block"
                      onClick={() => {
                        history.push("/educator/refer-and-earn-history");
                      }}
                    >
                      <img
                        src={
                          GITHUB_ASSETS_HOST_URL + `img/Educator_Icon/1 (1).png`
                        }
                        alt="Refer"
                      />
                      Refer & Earn History
                    </div>

                    <div
                      className="refer_class_block"
                      onClick={() => {
                        localStorage.setItem("websiteTour", "true");
                        history.push("/educator/dashboard");
                      }}
                    >
                      <img
                        src={
                          GITHUB_ASSETS_HOST_URL + `img/Educator_Icon/1 (1).png`
                        }
                        alt="Refer"
                      />
                      How it work
                    </div>

                    <div onClick={logoutHandler}>
                      <img
                        src={GITHUB_ASSETS_HOST_URL + `img/logout_icon.png`}
                        alt="Logout"
                      />
                      Logout
                    </div>
                  </div>
                </>
              )}
            </div>

            <div
              className="Navbar_Single_Icon"
              onClick={() => history.push("/educator/help-desk")}
              data-tut="reactour__Help_Desk"
            >
              <img
                src={GITHUB_ASSETS_HOST_URL + `img/Edu_Dashboard/Question.png`}
                alt="Question"
                className="Icon"
              />
            </div>
          </div>
          {notificationPannel && (
            <div className="notification-wrapper">
              <div className="notification-section">
                <div className="mark-view-all-section">
                  <div
                    className="mark-all-as-read"
                    onClick={() => {
                      if (
                        educatorNotificationCounter &&
                        educatorNotificationCounter > 0
                      ) {
                        allReadNotification();
                      }
                    }}
                  >
                    Read All
                  </div>
                  <NavLink to="/educator/notification" className="view-all">
                    View All
                  </NavLink>
                </div>
                {!loading &&
                educatorNotification &&
                educatorNotification.length > 0 ? (
                  educatorNotification.map((notification, index) => {
                    if (index <= 4) {
                      return (
                        <div
                          className="notification"
                          key={`notification_${index}`}
                          style={{
                            backgroundColor: !notification?.read
                              ? "#eff0f6"
                              : "",
                          }}
                          onClick={() => {
                            if (!notification.read) {
                              setReadNotification(notification?._id);
                            }
                          }}
                        >
                          <div className="notification-txt">
                            <img
                              src={
                                notification?.profilePic
                                  ? ASSETS_HOST_URL + notification?.profilePic
                                  : GITHUB_ASSETS_HOST_URL + `img/User.png`
                              }
                              className="notificatim-img"
                            />
                            <p>{notification?.message}</p>
                          </div>
                          <p className="notification-date">
                            {moment(notification?.date)
                              .local()
                              .format("DD, MMM YYYY")}
                          </p>
                        </div>
                      );
                    }
                  })
                ) : loading ? (
                  <i className="fa fa-spinner fa-spin"></i>
                ) : (
                  <div className="no-notification-section">
                    <p>No Notification For You</p>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      ) : (
        <div className="Responsive_Header_Container">
          <div onClick={showSidebar} className="educator_menu">
            <img
              src={GITHUB_ASSETS_HOST_URL + `img/Responsive/menu.png`}
              alt="menu"
              className="_Responsive_Img"
            />
          </div>
          <nav
            className={
              sidebar ? "nav-menu-educator active" : "nav-menu-educator"
            }
          >
            <li className="navbar-toggle" onClick={showSidebar}>
              <i className="fa fa fa-times"></i>
            </li>
            <ul
              className="nav-menu-items-edu"
              onClick={showSidebar}
              id="Edu_nav-menu-items"
            >
              {/* <li className="nav-text-educator">
                <Countdown
                  date={new Date(nextClassDetail?.date)}
                  renderer={(date) => CountdownRenderer(date)}
                />
              </li> */}
              {/* <div className="signin_with_meetube_btn">
                <button
                  onClick={() => {
                    signInWithMeetube()
                  }}
                >
                  {meetubeBtnText}
                </button>
              </div> */}
              <div className="free_class_btn">
                <button
                  onClick={() => {
                    PopUpHandler();
                  }}
                >
                  Get More Exposure
                </button>
              </div>
              {SidebarData.map((item, index) => {
                return (
                  <li key={index} className={item.cName}>
                    <Link to={item.path} className="Sidebar_Data">
                      <div className="Slider_Icon_Header">{item.icon}</div>
                      <span className="Title_Sidebar">{item.title}</span>
                    </Link>
                  </li>
                );
              })}

              {/* <div className="free_class_btn">
                <button
                  onClick={() => {
                    PopUpHandler();
                  }}
                >
                  Get More Exposure
                </button>
              </div> */}
            </ul>

            {/* <div className="Profile_DropDown">
              <img
                src={
                  // require("../../../../assets/img/proifle.jpg").default
                }
                alt="Profile"
                className="Profile"
              />

              <div className="DropDownIconContainer">
                <img
                  src={
                    // require("../../../../assets/img/Edu_Dashboard/Dropdown.svg").default
                  }
                  alt="DropDown"
                  className="DropDownIcon"
                />
              </div>
            </div> */}
          </nav>
          <div className="meeko_logo">
            <Link to="/dashboard">
              <img
                src={GITHUB_ASSETS_HOST_URL + `img/logo.png`}
                alt="logo"
                className="Logo_Responsive"
              />
            </Link>
          </div>
          <div className="educator_profile">
            {/* <div className="free_class_btn">
              <button
                onClick={() => {
                  PopUpHandler();
                }}
              >
                <img
                  src={
                    // require("../../../../assets/img/Edu_Dashboard/Question-white.png").default
                  }
                  alt="Question"
                  className="Icon"
                />
                  Get More Exposure
              </button>
            </div> */}
            <FreeclassPopup
              PopupShown={classPopup}
              ActionToParent={PopUpHandler}
              closePopup={() => setClassPopup(false)}
            />

            <div
              className="Navbar_Single_Icon_DropDown"
              onClick={() => {
                setDropDownIsOpen((value) => !value);
              }}
            >
              <img
                src={eduProfile ? ASSETS_HOST_URL + eduProfile : ""}
                alt="Profile"
                className="Profile_user"
              />

              <div className="DropDownIconContainer">
                <img
                  src={
                    GITHUB_ASSETS_HOST_URL + `img/Edu_Dashboard/Gray_Down.png`
                  }
                  alt="DropDown"
                  className="DropDownIcon"
                />
              </div>
              {DropDownIsOpen && (
                <>
                  <div className="dropdown_Main_Container_user">
                    <div
                      onClick={() => {
                        history.push("/educator/education-profile");
                      }}
                      className="edu_profile"
                    >
                      <img
                        src={GITHUB_ASSETS_HOST_URL + `img/user-icon.png`}
                        alt="Profile"
                      />
                      Profile
                    </div>

                    <div
                      className="refer_class_block"
                      onClick={() => {
                        history.push("/educator/refer-and-earn");
                      }}
                    >
                      <img
                        src={
                          GITHUB_ASSETS_HOST_URL + `img/Educator_Icon/1 (1).png`
                        }
                        alt="Refer"
                      />
                      Refer & Earn
                    </div>

                    <div
                      className="refer_class_block"
                      onClick={() => {
                        history.push("/educator/refer-and-earn-history");
                      }}
                    >
                      <img
                        src={
                          GITHUB_ASSETS_HOST_URL + `img/Educator_Icon/1 (1).png`
                        }
                        alt="Refer"
                      />
                      Refer & Earn History
                    </div>

                    <div
                      className="refer_class_block"
                      onClick={() => {
                        localStorage.setItem("websiteTour", "true");
                        history.push("/educator/dashboard");
                      }}
                    >
                      <img
                        src={
                          GITHUB_ASSETS_HOST_URL + `img/Educator_Icon/1 (1).png`
                        }
                        alt="Refer"
                      />
                      How it work
                    </div>

                    <div onClick={logoutHandler} className="edu_logout">
                      <img
                        src={GITHUB_ASSETS_HOST_URL + `img/logout_icon.png`}
                        alt="Logout"
                      />
                      Logout
                    </div>
                  </div>
                </>
              )}
            </div>
            <div
              className="notification_icon"
              onClick={() => {
                history.push("/educator/notification");
              }}
            >
              {educatorNotificationCounter &&
              educatorNotificationCounter > 0 ? (
                <div className="notification-count">
                  {educatorNotificationCounter &&
                  educatorNotificationCounter > 99
                    ? `99+`
                    : educatorNotificationCounter}
                </div>
              ) : null}
              <img
                src={GITHUB_ASSETS_HOST_URL + `img/Responsive/Notification.png`}
                alt="Notification"
              />
            </div>
          </div>
        </div>
      )}
      <Popup
        PopupShown={showPopup}
        ActionToParent={ShowPopUpHandler}
        eventHandler={logoutUser}
        Message={"Are you sure you want to logout?"}
      />
    </>
  );
};

const mapStateToProps = ({ auth, notification }: ApplicationState) => ({
  profile: auth.profile,
  userData: auth.userData,
  loading: notification.loading,
  educatorNotificationCounter: notification.educatorNotificationCounter,
  educatorNotification: notification.educatorNotification,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
  return {
    logoutUser: async () => {
      await dispatch(logoutUser());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EduHeader);
