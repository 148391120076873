import React, { useEffect } from "react"
import $ from "jquery"
import "./FreeclassPopup.scss"
import { useHistory } from "react-router"
import { GITHUB_ASSETS_HOST_URL } from "../../../helpers/api"
import useWindowSize from "../../dashboard/useWindowSize"

const FreeclassPopup = ({ PopupShown, ActionToParent, closePopup }) => {
  const history = useHistory()
  const { width, height }: any = useWindowSize()
  useEffect(() => {
    if (PopupShown) {
      $("body").css("overflow", "hidden")
      $("html").css("overflow", "hidden")
      $("#root").css("overflow", "hidden")
      $("#root").css("height", "100vh")
    } else {
      $("body").css("overflow", "auto")
      $("html").css("overflow", "auto")
      $("#root").css("overflow", "auto")
      $("#root").css("height", "")
    }
    return () => {}
  }, [PopupShown])
  return (
    <>
      {PopupShown ? (
        <>
          <div className="freeclass_popup">
            <div className="popup">
              <div
                className="popup_inner"
                style={{
                  width: `${width < 768 ? width - 30 + "px" : "70%"}`,
                  height: `${width < 768 ? height - 150 + "px" : "auto"}`,
                }}
              >
                <h1 className="popup_Title">
                  {" "}
                  Offer free classes or sessions to gain exposure &
                  opportunities. Give an overview of your class that may assist
                  a student or parent in deciding to enroll in your class.
                </h1>

                <img
                  src={GITHUB_ASSETS_HOST_URL + `img/popup-close.png`}
                  alt="Close"
                  onClick={closePopup}
                  className="Popup_Close_Btn"
                  style={{ cursor: "pointer", width: "30px", height: "30px" }}
                />

                <div className="add_btn">
                  <button
                    onClick={() => {
                      if (
                        history.location.pathname == "/educator/classes/free"
                      ) {
                        closePopup()
                      } else {
                        history.push("/educator/classes/free")
                      }
                    }}
                  >
                    {" "}
                    Create a Free Class
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  )
}

export default React.memo(FreeclassPopup)
